<script>
import { computed } from 'vue'
import ui from '/~/core/ui'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseCard from '/~/components/base/card/base-card.vue'
import { useCms } from '/~/composables/cms/use-cms'

export default {
  name: 'transaction-card',
  components: {
    BaseButton,
    BaseCard,
  },
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { isDarkThemeForEwallet } = useCms()

    const isLink = computed(() => ui.mobile && props.transaction.isPending)

    function onDelete() {
      emit('delete')
    }

    return {
      isDarkThemeForEwallet,
      isLink,
      onDelete,
    }
  },
}
</script>

<template>
  <component
    :is="isLink ? 'a' : 'div'"
    class="relative block"
    :class="[transaction.isDeleting && 'opacity-50']"
    :href="isLink && transaction.idvUrl"
  >
    <base-card>
      <div
        class="relative mx-auto h-full overflow-hidden rounded-xl bg-gradient-to-tr text-white shadow"
        :class="[
          transaction.isPending && 'from-amber-300 to-yellow-600',
          transaction.isFailed && 'from-red-400 to-red-600',
        ]"
      >
        <div class="flex h-full flex-col">
          <div class="mt-auto px-6 pb-5 font-bold">
            <div class="truncate text-xl">
              {{
                (transaction.isFailed && 'Failed') ||
                (transaction.isPending && 'Pending') ||
                transaction.status
              }}
            </div>
            <div class="mt-[5px]">
              {{ transaction.id }}
            </div>
          </div>
        </div>
        <div
          class="absolute bottom-0 left-0 z-0 h-24 w-96 -translate-x-[15px] translate-y-12 rotate-12 transform bg-white"
          style="opacity: 0.06"
        />
      </div>
      <div class="absolute right-0 top-0 -mr-[15px] -mt-[15px]">
        <base-button
          v-if="transaction.isFailed"
          class="z-1 rounded-full border-4 border-solid bg-fg-error p-2.5 text-white"
          :class="{
            'border-white': !isDarkThemeForEwallet,
          }"
          icon="trash"
          :disabled="transaction.isDeleting"
          size="sm"
          @click="onDelete()"
        />
      </div>
    </base-card>
  </component>
</template>
