<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import ui from '/~/core/ui'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseCard from '/~/components/base/card/base-card.vue'
import {
  usePhotoIds,
  usePhotoIdsTransactions,
  usePhotoId,
} from '/~/composables/photo-ids'
import PhotoIdTile from './components/photo-id-tile.vue'
import PhotoIdTransaction from './components/photo-id-transaction.vue'
import PhotoIdsEmpty from './components/photo-ids-empty.vue'
import PhotoIdsRedirect from './components/photo-ids-redirect.vue'
import PhotoIdWarning from './modals/photo-id-redirect-warning.vue'

export default {
  name: 'drawer-photo-ids',
  components: {
    PhotoIdTile,
    BaseCard,
    PhotoIdTransaction,
    PhotoIdWarning,
    BaseButton,
    BaseAsidePage,
    PhotoIdsEmpty,
    PhotoIdsRedirect,
  },
  setup() {
    const router = useRouter()
    const { setPhotoId } = usePhotoId()
    const { isPhotoIdsLoading, photoIds, hasNoPhotoIds, fetchPhotoIds } =
      usePhotoIds()
    const {
      isPhotoIdsTransactionsLoading,
      hasNoPhotoIdsTransactions,
      photoIdsTransactions,
      photoIdsIncompleteTransactions,
      photoIdsPendingTransactions,
      fetchPhotoIdsTransactions,
      deletePhotoIdsTransaction,
      hasPhotoIdsPendingTransactions,
    } = usePhotoIdsTransactions()

    const isRedirecting = ref(false)
    const showWarning = ref(false)

    async function onCreatePhotoId() {
      if (ui.mobile) {
        isRedirecting.value = true
      } else {
        showWarning.value = true
      }
    }

    function onReloadTransactions() {
      fetchPhotoIdsTransactions()
    }

    function onDelete(transaction) {
      deletePhotoIdsTransaction(transaction)
    }

    function onRedirectCancel() {
      isRedirecting.value = false
    }

    function onPhotoIdTileClick(photoId) {
      setPhotoId(photoId)

      router.push({
        hash: `#profile-photo-id/${photoId.id}`,
      })
    }

    fetchPhotoIds()
    fetchPhotoIdsTransactions()

    return {
      photoIds,
      photoIdsTransactions,
      photoIdsIncompleteTransactions,
      photoIdsPendingTransactions,
      hasNoPhotoIds,
      isPhotoIdsLoading,
      hasNoPhotoIdsTransactions,
      isPhotoIdsTransactionsLoading,
      hasPhotoIdsPendingTransactions,
      isRedirecting,
      showWarning,
      onDelete,
      onCreatePhotoId,
      onReloadTransactions,
      onRedirectCancel,
      onPhotoIdTileClick,
    }
  },
}
</script>

<template>
  <base-aside-page title="Photo IDs">
    <template #action>
      <base-button
        v-if="hasPhotoIdsPendingTransactions"
        :disabled="isPhotoIdsTransactionsLoading || isRedirecting"
        icon="plain/reload"
        class="flex h-10 w-10 items-center justify-center rounded-full bg-white text-primary"
        :class="[isPhotoIdsTransactionsLoading && 'animate-spin']"
        @click="onReloadTransactions"
      />

      <base-button
        v-else-if="!isPhotoIdsTransactionsLoading"
        :disabled="isRedirecting"
        icon="plus"
        class="flex h-10 w-10 items-center justify-center rounded-full bg-white text-primary"
        @click="onCreatePhotoId"
      />
    </template>

    <photo-ids-redirect v-if="isRedirecting" @back="onRedirectCancel" />

    <photo-ids-empty
      v-else-if="hasNoPhotoIdsTransactions && hasNoPhotoIds"
      @create="onCreatePhotoId"
    />

    <div v-else class="mx-auto w-72 max-w-full space-y-[30px] py-2.5">
      <base-card
        v-if="isPhotoIdsLoading"
        class="animate-pulse rounded-xl bg-strong"
      />
      <template v-else>
        <photo-id-tile
          v-for="photoId in photoIds"
          :key="photoId.id"
          class="cursor-pointer"
          :document="photoId"
          @click="onPhotoIdTileClick(photoId)"
        />
      </template>

      <base-card
        v-if="isPhotoIdsTransactionsLoading"
        class="animate-pulse rounded-xl bg-strong"
      />
      <template v-else>
        <photo-id-transaction
          v-for="transaction in photoIdsIncompleteTransactions"
          :key="transaction.id"
          :transaction="transaction"
          @delete="onDelete(transaction)"
        />
      </template>
    </div>

    <photo-id-warning
      v-show="showWarning"
      :to="$attrs.to || 'menu-modal'"
      :visible.sync="showWarning"
    />
  </base-aside-page>
</template>
