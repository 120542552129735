<script>
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseState from '/~/components/base/state/base-state.vue'

export default {
  name: 'drawer-photo-ids',
  components: {
    BaseButton,
    BaseState,
  },
  emits: ['create'],
}
</script>

<template>
  <base-state
    title="Add a Photo ID"
    image="/empty/empty-ids.svg"
    design="recroom"
    class="min-h-full"
  >
    <p>
      Securely store and manage your Drivers License and Passport for easy
      access anytime.
    </p>
    <template #footer>
      <base-button @click="$emit('create')">Add a Photo ID</base-button>
    </template>
  </base-state>
</template>
