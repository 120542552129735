<script>
import BaseButton from '/~/components/base/button/base-button.vue'
import DrawerIconHeader from '/~/components/drawer/components/drawer-icon-header.vue'
import DrawerModal from '/~/components/drawer/components/drawer-modal.vue'
import { useCms } from '/~/composables/cms/use-cms'

export default {
  name: 'profile-photo-id',
  components: {
    DrawerIconHeader,
    DrawerModal,
    BaseButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: 'menu-modal',
    },
  },
  setup() {
    const { ewalletLabels } = useCms()

    return {
      ewalletLabels,
    }
  },
  methods: {
    hide() {
      this.$emit('update:visible', false)
    },
  },
}
</script>

<template>
  <drawer-modal :to="to" :visible="visible" @hide="hide">
    <template #iconHeader>
      <drawer-icon-header
        title="Photo IDs can only be added using your mobile phone"
        icon="secure"
        :action="hide"
      />
    </template>
    <div class="px-5 pb-[30px]">
      <div class="pb-10">
        Login to your {{ ewalletLabels.ewallet }} using your mobile phone to
        securely store and validate your Australian Drivers License or Passport.
      </div>

      <base-button full-width @click="hide">Got it</base-button>
    </div>
  </drawer-modal>
</template>
